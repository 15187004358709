import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type { IMissionImageEntry } from 'interfaces/File.interface';
import {
  IPostMissionImageArgs,
  IPostMissionImageEntryArgs,
  IMissionImageResponse,
  IDeleteMissionImageArgs,
  IMissionImageEntityArgs,
} from './missionImages.api.types';

export const getMissionImageEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IMissionImageEntityArgs,
  BaseQueryFn,
  string,
  IMissionImageResponse
> =>
  build.query<IMissionImageResponse, IMissionImageEntityArgs>({
    query: (queryArg: IMissionImageEntityArgs) => ({
      url: `api/mission_images/${queryArg.id}`,
    }),
  });

export const postMissionImageEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostMissionImageEntryArgs,
  BaseQueryFn,
  string,
  IMissionImageEntry
> =>
  build.mutation<IMissionImageEntry, IPostMissionImageEntryArgs>({
    query: (queryArg) => ({
      url: `api/mission_images`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const deleteMissionImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IDeleteMissionImageArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IDeleteMissionImageArgs>({
    query: (queryArg) => ({
      url: `api/mission_images/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const postMissionImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostMissionImageArgs,
  BaseQueryFn,
  string,
  IMissionImageEntry
> =>
  build.mutation<IMissionImageEntry, IPostMissionImageArgs>({
    query: (queryArg) => ({
      url: `api/mission_images/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });
