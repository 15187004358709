import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type {
  ISelectContactValue,
  ISelectValue,
} from 'interfaces/Data/SelectValue.interface';
import type { TExternalDataValues } from './externalDataValues.types';
import { useGetAbsenceCategoriesQuery } from 'services/teamHeroApi.service';
import mapResultByType from './mapResultByType.helper';
import { useGetPublicApplicationJobAdsQuery } from 'services/publicTeamHeroApi.service';

export const useGetSelectExternalOptionValues = (
  externalDataType?: TExternalDataValues,
  skip = false,
  additionalFilters: ISimpleFilter[] = []
): { data: ISelectValue[] & ISelectContactValue[]; isLoading: boolean } => {
  let filterValuesResult: ISelectValue[] = [];

  const scalarFieldsFilter: ISimpleFilter = {
    key: 'scalarFieldsOnly',
    operator: 'AND',
    value: true,
  };

  const { data: categoryData, isLoading: isCategoryLoading } =
    useGetAbsenceCategoriesQuery(
      {},
      {
        skip:
          skip || !externalDataType || externalDataType !== 'absenceCategory',
      }
    );

  const { data: applicationJobAdsData, isLoading: isApplicationJobAdsLoading } =
    useGetPublicApplicationJobAdsQuery(
      {
        pagination: false,
        filters: [scalarFieldsFilter, ...additionalFilters],
      },
      { skip: skip || externalDataType !== 'jobAd' }
    );

  const externalValuesResult = [categoryData, applicationJobAdsData].find(
    (externalDataItem) => !!externalDataItem
  );

  filterValuesResult = mapResultByType(externalDataType, externalValuesResult);

  return {
    data: filterValuesResult,
    isLoading: isCategoryLoading || isApplicationJobAdsLoading,
  };
};
